import flattenDeep from 'lodash/flattenDeep'
import max from 'lodash/max'
import min from 'lodash/min'
import React, { memo } from 'react'
import { css } from '@emotion/react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts-new'
import useMedia from '~/hooks/useMedia'
import { globalGrey, globalRed, globalGreen } from '~/modules/AppLayout/Colors'
import { toPercentage } from '~/utils/toPercentage'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useThemeStore } from '~/components/theme/useThemeStore'

export const CandleBarChart = memo<{
  symbols: string[]
  hoverDelegate?: React.Dispatch<React.SetStateAction<Signalr.SymbolString>>
  shouldCustomizedXLabel?: boolean
  adjustX?: number
  hide?: boolean
}>(function CandleBarChart(props) {
  const theme = useThemeStore(s => s.theme)

  const lineFill = theme === 'dark' ? globalGrey.g50 : '#353535'

  const quoteArray = useSignalrStoreValueOHLC(state =>
    state.useGroupOHLC(props.hide ? [] : props.symbols),
  )
    .map(q => (q ? toPercentageWay(q) : undefined))
    .filter(q => q)

  const minValue = min(flattenDeep(quoteArray.map(d => [d?.low, d?.oc[1], d?.oc[0]]))) ?? -0.05
  const maxValue = max(flattenDeep(quoteArray.map(d => [d?.high, d?.oc[1], d?.oc[0]]))) ?? 0.05
  const yRange: [number, number] = [
    minValue < -5 ? -10 : minValue < -3 ? -5 : -3,
    maxValue > 5 ? 10 : maxValue > 3 ? 5 : 3,
  ]
  const yTicks: number[] =
    Math.abs(minValue) > 5 || Math.abs(maxValue) > 5
      ? [-10, -5, -1, 1, 5, 10]
      : Math.abs(minValue) > 2 || Math.abs(maxValue) > 2
      ? [-5, -3, -1, 1, 3, 5]
      : [-3, -1, 1, 3]
  return props.hide ? null : (
    <ResponsiveContainer>
      <BarChart
        data={quoteArray}
        margin={{
          left: 24,
        }}
      >
        <YAxis
          mirror={true}
          ticks={yTicks}
          domain={yRange}
          tick={p => CustomizedYAxisLabel(theme, p)}
        />
        <CartesianGrid
          strokeDasharray='1 1'
          stroke={globalGrey.g600}
        />
        <ReferenceLine
          y={0}
          stroke={lineFill}
          width={10}
        />
        <Bar
          onMouseEnter={e => props.hoverDelegate && props.hoverDelegate(e.symbol)}
          dataKey='oc'
          fill='transparent'
          shape={<Candlestick />}
        />

        <XAxis
          scale='point'
          padding={{ left: 20, right: 15 }}
          interval={0}
          dataKey='symbol'
          stroke={lineFill}
          tick={
            props.shouldCustomizedXLabel ? (
              <CustomizedXLabel adjustX={-7} />
            ) : (
              <CustomizedXLabel adjustX={-8} />
            )
          }
          tickLine={false}
          axisLine={false}
        />
      </BarChart>
    </ResponsiveContainer>
  )
})

/**
 * 針對y軸的bar值我們是給oc這個key，餵給bar吃到height（先假設bar是預設的樣子），因此[open,close]的差值便會是對應的height。 原先求 ratio 由
 * Math.abs(height / (open - close)) 而得之，此為為了知道資料本身對應到圖上的比例尺為多少，
 * 進而透過得出的比例尺（ratio）能推敲出[high,low]等等要畫線條時的參考長度
 * 但由於有些[open,close]差值為0，算出的ratio會是NaN，造成有些symbol會沒有high,low，所以才會寫值數值19.09。
 */
const MAGIC_RATIO = 15.09
const Candlestick = (props: any) => {
  const theme = useThemeStore(s => s.theme)
  const lineFill = theme === 'dark' ? globalGrey.g50 : '#555555'

  const { x, y, height, open, high, low, close } = props
  const width = props.width - 10
  const isGrowing = open < close
  const color = open - close === 0 ? 'black' : isGrowing ? globalRed.r400 : globalGreen.g400

  const draw = (
    <g
      stroke={lineFill}
      fill={color}
      strokeWidth='1'
    >
      {/* 箱子 */}
      <path
        d={`
          M ${x - 2},${y}
          L ${x - 2},${y + height}
          L ${x + width + 2},${y + height}
          L ${x + width + 2},${y}
          L ${x - 2},${y}
        `}
      />
      {/* 下半部的線 */}
      {/* red: 開盤y加上高度（開收盤距離）開始往下畫出（開-低）的距離; green: 開盤y開始往下畫出（收-低）的距離 */}
      {isGrowing ? (
        <path
          d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - low) * MAGIC_RATIO}
          `}
        />
      ) : (
        <path
          d={`
            M ${x + width / 2}, ${y}
            v ${(close - low) * MAGIC_RATIO}
          `}
        />
      )}
      {/* 上半部的線 */}
      {/* RED: 收盤y開始往上畫出（收-高）的距離; GREEN: 開盤y加上高度（開收盤距離）開始往上畫出（開-高）的距離 */}
      {isGrowing ? (
        <path
          d={`
            M ${x + width / 2}, ${y}
            v ${(close - high) * MAGIC_RATIO}
        `}
        />
      ) : (
        <path
          d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - high) * MAGIC_RATIO}
          `}
        />
      )}
    </g>
  )
  return draw
}

const CustomizedXLabel = (props: any) => {
  const { x, y, payload } = props
  const { isPhone } = useMedia()

  const theme = useThemeStore(s => s.theme)

  const fill = theme === 'dark' ? globalGrey.g50 : '#252525'

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={isPhone ? 10 : 12}
        fontWeight={900}
        x={props.adjustX ?? 10}
        y={0}
        dy={10}
        textAnchor='end'
        fill={fill}
        transform='rotate(0)'
        css={css`
          writing-mode: vertical-lr;
        `}
      >
        {payload.value ? <SymbolName symbol={payload.value}></SymbolName> : ''}
      </text>
    </g>
  )
}

const CustomizedYAxisLabel = (
  theme: string,
  tickProps: { x: number; y: number; payload: { value: number } },
) => {
  const {
    x,
    y,
    payload: { value },
  } = tickProps

  const fill = theme === 'dark' ? globalGrey.g50 : '#555555'

  return (
    <svg>
      <text
        x={x - 8}
        y={y + 3}
        textAnchor='end'
        fontSize='12'
        fill={fill}
      >
        {value}%
      </text>
    </svg>
  )
}

type AddOnValueOfOHLC = {
  delta: number
  // open close 對應的percentage
  oc: [number, number]
  closeChangePercentString: string
}

const toPercentageWay = (data: Signalr.ValueOfOHLC) => {
  const quotePercentage: Signalr.ValueOfOHLC & AddOnValueOfOHLC = {
    ...data,
    oc: [0, 0],
    delta: 0,
    closeChangePercentString: '',
  }

  // WORKAORUND: 要是昨日沒開盤就會有等於零的狀況，造成變動是 Infinity
  if (!quotePercentage) return undefined
  const backPref = data.prevRef === 0 ? data.close : data.prevRef
  quotePercentage.symbol = data.symbol

  quotePercentage.open = toPercentage(data.open, backPref, true)
  quotePercentage.high = toPercentage(data.high, backPref, true)

  quotePercentage.low = toPercentage(data.low, backPref, true)
  quotePercentage.close = toPercentage(data.close, backPref, true)

  quotePercentage.oc[0] = toPercentage(data.open, backPref, true)
  quotePercentage.oc[1] = toPercentage(data.close, backPref, true)

  quotePercentage.closeChangePercentString =
    quotePercentage.oc[1] > 0 ? `+${quotePercentage.oc[1]}%` : `${quotePercentage.oc[1]}%`

  return quotePercentage
}
